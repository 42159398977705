<template>
  <!-- <PopupLoginComponent v-if="productCode && $h.isBlank(userToken)" :kol="KOL_CODE" :postkey="PostKey" :productCode="productCode" :f7router="f7router" /> -->
  <f7-page class="productview-page alt-productview-page">
    <template #fixed>
      <Suspense>
        <ProductNavigationComponent :hidechat="true" :title="helpers.getAttribute(Product, $t.getLanguage(), 'Name')" :type="navType" :search="false" />
      </Suspense>
      <Suspense>
        <ProductToolbarComponent v-if="Product && Product.ProductKey" :kol="KOL_CODE" :postkey="PostKey" :product="Product" />
      </Suspense>
    </template>

    <f7-photo-browser :photos="photos" id="popup" ref="popup" theme="dark" type="popup" :popup-close-link-text="$t.getTranslation('BTN_CLOSE')" :routable-modals="false"></f7-photo-browser>

    <f7-photo-browser :photos="descriptionPhotos" id="popup" ref="popupDark" theme="dark" type="popup" :popup-close-link-text="$t.getTranslation('BTN_CLOSE')" :routable-modals="false"></f7-photo-browser>

    <div v-if="isGettingProd" class="preloader-cont">
      <f7-preloader></f7-preloader>
    </div>

    <section v-else class="productview-container">
      <!-- sticker for sku  -->
      <div class="prod-slider-container-sale" v-if="Product?.ProductSaleStatus === 'ACTIVE' || Product?.GroupBuyId > 0">
        <f7-swiper class="prod-swiper-sale" :pagination="{ clickable: true }">
          <f7-swiper-slide class="flash-content" v-if="!Product?.Images.length">
            <div
              class="prod-image-disp-sale"
              :style="{
                'background-image': 'url(' + helpers.getImage(null, 'PRODUCT') + ')'
              }"
            ></div>
            <div class="flash-badge" v-if="Product?.ProductSaleStatus === 'ACTIVE'">
              <span>{{ $t.getTranslation('LBL_ISSALE') }}</span>
              <img :src="require('@/assets/images/flash-sale-icon-small.png')" loading="lazy" />
            </div>
          </f7-swiper-slide>

          <f7-swiper-slide class="flash-content" v-for="(item, index) in Product.Images" :key="item.ProductImageId">
            <div
              @click="viewImage($refs, index)"
              class="prod-image-disp-sale"
              :style="{
                'background-image': 'url(' + helpers.getImage(item.FileName, 'PRODUCT') + ')'
              }"
            ></div>
            <div class="flash-badge" v-if="Product?.ProductSaleStatus === 'ACTIVE'">
              <span>{{ $t.getTranslation('LBL_ISSALE') }}</span>
              <img :src="require('@/assets/images/flash-sale-icon-small.png')" />
            </div>
          </f7-swiper-slide>

          <f7-swiper-slide class="flash-content" v-if="Product.VideoLink">
            <div class="prod-image-disp-sale post-rel-sale">
              <video controls muted>
                <source :src="Product.VideoLink" type="video/mp4" />
                {{ $t.getTranslation('LBL_BROWSER_NO_SUPPORT_VIDEO') }}
              </video>
            </div>
            <div class="flash-badge">
              <span>{{ $t.getTranslation('LBL_ISSALE') }}</span>
              <img :src="require('@/assets/images/flash-sale-icon-small.png')" />
            </div>
          </f7-swiper-slide>
        </f7-swiper>
        <Suspense>
          <FlashSaleComponent :data="Product" :getProduct="getProduct" />
        </Suspense>

        <div v-if="Product?.PackingQty?.Total" class="packing-qty-sale">
          <font-awesome-icon :icon="['fal', 'wine-bottle']" fixed-width /> x
          {{ Product.PackingQty.Total }}
        </div>
      </div>

      <div v-else>
        <div class="ribbon-container" v-if="!userToken">
          <div class="ribbon-new ribbon-top-left-new" v-if="Product?.IsNew && Product?.IsNew > 0">
            <span> {{ $t.getTranslation('LBL_ISNEW') }}</span>
          </div>
          <div class="ribbon-hot ribbon-top-left-hot" v-if="Product?.IsHot && Product?.IsHot > 0">
            <span>{{ $t.getTranslation('LBL_ISHOT') }} </span>
            <div class="bg-sticker">
              <img :src="require('@/assets/images/sticker-flower.png')" loading="lazy" />
            </div>
          </div>
          <div class="ribbon-sal ribbon-top-left-sal" v-if="Product?.IsSale && Product?.IsSale > 0">
            <span>{{ $t.getTranslation('LBL_ISSALE') }}</span>
            <div class="bg-sticker">
              <img :src="require('@/assets/images/sticker-flower.png')" loading="lazy" />
            </div>
          </div>
          <div class="ribbon-best ribbon-top-left-best" v-if="Product?.IsBest && Product?.IsBest > 0">
            <span>{{ $t.getTranslation('LBL_ISBEST') }}</span>
            <div class="bg-sticker">
              <img :src="require('@/assets/images/sticker-best2.png')" loading="lazy" />
            </div>
          </div>
          <div v-if="data?.PackingQty && data?.PackingQty > 1" class="packing-qty-view">
            <font-awesome-icon :icon="['fal', 'wine-bottle']" fixed-width /> x
            {{ data.PackingQty }}
          </div>
        </div>

        <div class="prod-slider-container">
          <f7-swiper class="prod-swiper" :pagination="{ clickable: true }">
            <f7-swiper-slide v-if="!Product?.Images.length">
              <div
                class="prod-image-disp"
                :style="{
                  'background-image': 'url(' + helpers.getImage(null, 'PRODUCT') + ')'
                }"
              ></div>
            </f7-swiper-slide>

            <f7-swiper-slide v-for="(item, index) in Product.Images" :key="item.ProductImageId">
              <div
                @click="viewImage($refs, index)"
                class="prod-image-disp"
                :style="{
                  'background-image': 'url(' + helpers.getImage(item.FileName, 'PRODUCT') + ')'
                }"
              ></div>
            </f7-swiper-slide>

            <f7-swiper-slide v-if="Product.VideoLink">
              <div class="prod-image-disp post-rel">
                <video controls muted>
                  <source :src="Product.VideoLink" type="video/mp4" />
                  {{ $t.getTranslation('LBL_BROWSER_NO_SUPPORT_VIDEO') }}
                </video>
              </div>
            </f7-swiper-slide>
          </f7-swiper>

          <div v-if="Product?.PackingQty?.Total && !Product?.IsBundled" class="packing-qty-sale">
            <font-awesome-icon :icon="['fal', 'wine-bottle']" fixed-width /> x
            {{ Product.PackingQty.Total }}
          </div>
        </div>
      </div>

      <div class="info">
        <div>
          <div v-if="Product?.Price?.DiscountPrice" style="display: flex; align-items: center">
            <span v-if="Product?.IsFuture == 0">
              <span v-if="Product?.ProductSaleAmount && Product?.ProductSaleStatus == 'ACTIVE'">
                <h3 class="price sale-price">
                  {{ helpers.formatPrice(Product?.Price?.RegularSellingPrice, Product?.Price?.CurrencyCode) }}
                </h3>
                <h3 class="price">
                  {{ helpers.formatPrice(Product?.ProductSaleAmount, Product?.Price?.CurrencyCode) }}
                </h3>
              </span>
              <span v-else>
                <h3 class="price sale-price">
                  {{ helpers.formatPrice(Product?.Price?.RegularSellingPrice, Product?.Price?.CurrencyCode) }}
                </h3>
                <h3 class="price">
                  {{ helpers.formatPrice(Product?.Price?.DiscountPrice, Product?.Price?.CurrencyCode) }}
                </h3>
              </span>
            </span>
          </div>
          <h3 v-else class="price">
            <span v-if="Product?.IsFuture == 0 && !(Product?.ProductSaleStatus == 'ACTIVE')">
              {{ helpers.formatPrice(Product?.Price?.RegularSellingPrice, Product?.Price?.CurrencyCode) }}
            </span>
            <span v-else>
              <h3 class="price sale-price">
                {{ helpers.formatPrice(Product?.Price?.RegularSellingPrice, Product?.Price?.CurrencyCode) }}
              </h3>
              <h3 class="price">
                {{ helpers.formatPrice(Product?.ProductSaleAmount, Product?.Price?.CurrencyCode) }}
              </h3>
            </span>
          </h3>
        </div>

         <h3 class="price tax" v-if="$configs?.isTaxInclusive">
          {{ $t.getTranslation('LBL_TAX_INCLUSIVE') }}
        </h3>

        <h3 class="price tax" v-if="Product?.IsFuture == 0 && userToken && !$configs?.isTaxInclusive">
          {{ $t.getTranslation("LBL_ESTIMATED_TAX") }}
          {{ helpers.getTaxRate(Product?.Price?.DiscountPrice || Product?.Price?.RegularSellingPrice, Product?.TaxRate, Product?.Price?.CurrencyCode, null, Product?.CategoryTaxRate, Product?.SubSkus) }}
        </h3>

        <h3 class="price tax" v-if="Product?.ProductSaleStatus == 'ACTIVE' && Product.QtyPerUser > 0">
          {{ $t.getTranslation('LBL_AVAILABLE_SALE_BOTTLES') }}
          <font-awesome-icon :icon="['fal', 'wine-bottle']" fixed-width />
          <span style="font-weight: 700">{{ Product.CurrentProductSaleUserQty }} / {{ Product.QtyPerUser }}</span>
        </h3>
        <p style="font-size: 12px" v-if="Product?.ShippingStatusCode == 'ACTIVE' && Product?.ShippingFee > 0">
          {{ $t.getTranslation('LBL_MERCHANT_SHIPPING_PER_PRODUCT') }}
        </p>
        <Suspense>
          <ProductInventoryStatus v-if="Product?.IsFuture == 0 && userToken" :product="Product" />
        </Suspense>

        <h1>{{ helpers.getAttribute(Product, $t.getLanguage(), 'Name') }}</h1>
        <h5>
          <span v-if="$t.getLanguage() == 'EN'">
            {{ helpers.getAttribute(Product, 'CHS', 'Name') }}
          </span>
          <span v-else>
            {{ helpers.getAttribute(Product, 'EN', 'Name') }}
          </span>
        </h5>
        <star-rating v-if="Product?.IsFuture == 0" :increment="0.1" :star-size="13" :show-rating="false" :rating="Product.Rating || 0" :read-only="true"></star-rating>

        <div v-if="Product.Rating == 0 && Product?.IsFuture == 0" :class="$t.getLanguage() === 'EN' ? 'critic-score-parent' : 'critic-score-parent-chs'">
          <div v-for="(value, name, index) in Product.Critic" class="critic-score-child" :class="translationCritic[name].translation === 'LBL_CRITIC_SCORE_WS' ? 'make-flex' : ''" v-bind:key="index">
            <img v-if="translationCritic[name].translation === 'LBL_CRITIC_SCORE_WS'" src="../../assets/images/wine-spec.jpg" alt="img" class="ws-img" />
            <label v-else>{{ $t.getTranslation(translationCritic[name].translation) }}</label>
            <span>{{ value }}</span>
          </div>
        </div>

        <p v-if="Product?.IsFuture == 0">
          {{ helpers.getAttribute(Product, $t.getLanguage(), 'Description') }}
        </p>
        <br />

        <div class="bundle-container" v-if="Product?.IsBundled">
          <h4>{{ $t.getTranslation('LBL_SUB_ITEMS') }}</h4>

          <!-- <div class="bundle-product-container">
            <div v-for="(item, index) in Product.SubSkus" :key="item.ProductId + index" class="image-container">
              <img :src="helpers.getImage(item.PrimaryImage, 'PRODUCT', null, true)" :alt="$t.getTranslation('LBL_PRODUCT_IMAGE')" class="product-image" />
            </div>
          </div> -->

          <Suspense>
            <masonry-layout id="productMasonry" cols="2" gap="10">
              <template v-for="(item, index) in Product.SubSkus" :key="item.ProductId + index">
                <ProductBundleCardComponent :data="item" :bundleId="Product.BundleProductId" :showPrice="false" />
              </template>
            </masonry-layout>
          </Suspense>

          <!-- <f7-swiper :slides-per-view="'auto'" :space-between="0" navigation>
            <f7-swiper-slide v-for="(item, index) in Product.SubSkus" :key="item.ProductId + index">
              <ProductListCardComponent :data="item" />
            </f7-swiper-slide>
          </f7-swiper> -->
        </div>

        <div class="bundle-container" v-if="RelatedBundleProducts.length > 0">
          <h4>{{ $t.getTranslation('LBL_RELATED_PRODUCT') }}</h4>

          <div class="bundle-product-container">
            <div v-for="(item, index) in RelatedBundleProducts" :key="item.ProductId + index" class="image-container" @click="onViewProduct(item.ProductKey)">
              <img :src="helpers.getImage(item.PrimaryImage, 'PRODUCT', null, true)" :alt="$t.getTranslation('LBL_PRODUCT_IMAGE')" class="product-image" loading="lazy" />
            </div>
          </div>

          <!-- <masonry-layout id="productMasonry" cols="2" gap="10">
            <template v-for="(item, index) in RelatedBundleProducts" :key="item.ProductId + index">
              <ProductGridCardComponent :data="item" :bundleId="BundleProductId" />
            </template>
          </masonry-layout> -->

          <!-- <f7-swiper :slides-per-view="'auto'" :space-between="0" navigation>
            <f7-swiper-slide v-for="(item, index) in Product.SubSkus" :key="item.ProductId + index">
              <ProductListCardComponent :data="item" />
            </f7-swiper-slide>
          </f7-swiper> -->
        </div>

        <div v-if="!Product?.IsBundled">
          <div class="prod-details-field" v-if="Product.HKRetailPrice && userToken">
            <label>{{ $t.getTranslation('FRM_HK_RETAIL_PRICE_REF') }}</label>
            <span style="white-space: nowrap; display: inline-block">{{ helpers.formatPriceRange(Product?.HKRetailPrice, Product?.Price?.CurrencyCode) }}</span>
          </div>

          <div v-if="Product?.VideoLink" class="video-container">
            <video controls muted>
              <source :src="Product?.VideoLink" type="video/mp4" />
              {{ $t.getTranslation('LBL_BROWSER_NO_SUPPORT_VIDEO') }}
            </video>
          </div>

          <template v-if="!Product.IsAccessory">
            <div class="prod-details-field" @click="goMerchant(Product)">
              <label>{{ $t.getTranslation('LBL_MERCHANT') }}</label>
              <span>{{ Product.MerchantName || '---' }}</span>
            </div>

            <div v-if="Product?.IsAccessory" class="prod-details-field">
              <label>{{ $t.getTranslation('LBL_BRAND') }}</label>
              <span>{{ helpers.getAttribute(Product, $t.getLanguage(), 'Brand') || '---' }}</span>
            </div>

            <div v-if="Product?.IsAccessory" class="prod-details-field">
              <label>{{ $t.getTranslation('LBL_MATERIALS') }}</label>
              <span>{{ helpers.getAttribute(Product, $t.getLanguage(), 'Materials') || '---' }}</span>
            </div>

            <div v-if="!Product?.IsAccessory" class="prod-details-field">
              <label>{{ $t.getTranslation('LBL_VINTAGE') }}</label>
              <span>{{ Product.Year || '---' }}</span>
            </div>

            <div class="prod-details-field">
              <label>{{ $t.getTranslation('LBL_TYPE') }}</label>
              <span>{{ Product.CategoryName || '---' }}</span>
            </div>

            <div v-if="!Product?.IsAccessory" class="prod-details-field">
              <label>{{ $t.getTranslation('LBL_SIZE') }}</label>
              <span>{{ Product.Size || '---' }}</span>
            </div>

            <div class="prod-details-field">
              <label>{{ $t.getTranslation('LBL_COUNTRY_OF_ORIGIN') }}</label>
              <span>{{ helpers.getAttribute(Product, $t.getLanguage(), 'CountryOfOrigin') || '---' }}</span>
              <span class="flag-icon"><country-flag :country="Product.CountryCode" size="normal"/></span>
            </div>

            <div v-if="!Product?.IsAccessory" class="prod-details-field">
              <label>{{ $t.getTranslation('LBL_REGION_1') }}</label>
              <span>{{ helpers.getAttribute(Product, $t.getLanguage(), 'Region1') || '---' }}</span>
            </div>

            <div v-if="!Product?.IsAccessory" class="prod-details-field">
              <label>{{ $t.getTranslation('LBL_REGION_2') }}</label>
              <span>{{ helpers.getAttribute(Product, $t.getLanguage(), 'Region2') || '---' }}</span>
            </div>

            <div v-if="!Product?.IsAccessory && Product?.Winery" class="prod-details-field">
              <label>{{ $t.getTranslation('LBL_WINERY') }}</label>
              <span>{{ helpers.concatAttributeNameCsv(Product.Winery, 'Winery') || '---' }}</span>
            </div>
          </template>

          <template v-else>
            <div class="prod-details-field">
              <label>{{ $t.getTranslation('LBL_BRAND') }}</label>
              <span>
                {{ helpers.getAttribute(Product, $t.getLanguage(), 'Brand') || '---' }}
              </span>
            </div>

            <div class="prod-details-field">
              <label>{{ $t.getTranslation('LBL_COUNTRY_OF_ORIGIN') }}</label>
              <span>{{ helpers.getAttribute(Product, $t.getLanguage(), 'CountryOfOrigin') || '---' }}</span>
              <span class="flag-icon"><country-flag :country="Product.CountryCode" size="normal"/></span>
            </div>

            <div class="prod-details-field">
              <label>{{ $t.getTranslation('LBL_SPECIFICATIONS') }}</label>
              <span>
                {{ helpers.getAttribute(Product, $t.getLanguage(), 'Specs') || '---' }}
              </span>
            </div>

            <div class="prod-details-field">
              <label>{{ $t.getTranslation('LBL_MEASUREMENTS') }}</label>
              <span>
                {{ helpers.getAttribute(Product, $t.getLanguage(), 'Uom') || '---' }}
              </span>
            </div>

            <div class="prod-details-field">
              <label>{{ $t.getTranslation('LBL_MATERIALS') }}</label>
              <span>
                {{ helpers.getAttribute(Product, $t.getLanguage(), 'Materials') || '---' }}
              </span>
            </div>
          </template>
        </div>

        <f7-list v-if="Product?.IsFuture == 0 && !Product?.IsAccessory && !Product?.IsBundled">
          <!-- <f7-list-item :title="$t.getTranslation('LBL_VINYARD_DETAILS')" link="/shop/product/view/vineyard/:code">
            <template #media>
              <font-awesome-icon class="active" :icon="['fas', 'seedling']" fixed-width />
            </template>
          </f7-list-item> -->
          <f7-list-item :title="$t.getTranslation('LBL_TASTING_NOTES')" :link="'/shop/product/view/tasting/' + Product.ProductKey">
            <template #media>
              <font-awesome-icon class="active" :icon="['fas', 'sticky-note']" fixed-width />
            </template>
          </f7-list-item>
        </f7-list>

        <div v-if="ProductDescriptionImages.length > 0 || Product?.Winery || Product?.Grapes">
          <h3 v-if="ProductDescriptionImages.length > 0">
            {{ $t.getTranslation('LBL_PRODUCT_DESCRIPTION') }}
          </h3>

          <div
            style="width: 100%; height: 100%; background-size: contain; background-position: center; background-repeat: no-repeat"
            v-for="img in ProductDescriptionImages"
            :key="'prd_img_' + img.ProductId"
            @click="openImage(img.FileName, 'PRODUCT', $refs)"
          >
            <img :src="helpers.getImage(img.FileName, 'PRODUCT')" style="width: 100%; height: 100%" loading="lazy" />
          </div>

          <h3 v-if="Product?.Grapes && Product?.Grapes.length > 0 && isGrapes.length > 0">
            {{ $t.getTranslation('LBL_GRAPES') }}
          </h3>
          <div
            style="width: 100%; height: 100%; background-size: contain; background-position: center; background-repeat: no-repeat"
            v-for="img in Product.Grapes"
            :key="'grapes_img_' + img.GrapesId"
            @click="openImage(img.FileName, 'GRAPES', $refs)"
          >
            <img v-if="img.FileName" :src="helpers.getImage(img.FileName, 'GRAPES')" style="width: 100%; height: 100%" loading="lazy" />
          </div>

          <div class="prod-details-field-notice" v-if="!$configs?.hideProductPrice && userToken">
            <label>品酒坊购买须知</label>
            <span
              >您好! 为帮助您更好地选购跨境商品，请您在购买前务必认真、详细阅读并完全理解本须知的全部内容， 并对自身风险承担做出客观判断。请您在同意本须知的内容后再下单购买：
              <ol class="notice-list">
                <li>据海关相关规定，您选购的跨境商品仅限个人自用，不得进行再次销售。</li>
                <li>
                  为顺利安排您的订单发运，根据中国跨境电商零售进口相关规定，订单购买人、支付人与收件人必须一致，用户（支付人）需要提供正确的中国身份证号及与身份证一致的姓名（特请注意身份证尾号为“X”的客户，请您填写大写字母“X”
                  ，否则订单无法清关）。
                </li>
                <li>单笔订单交易总金额上限为人民币5000元，选购超过5000元请联系客服。</li>
                <li>请注意中国海关的购买限额记录，跨境电商产品的个人年度购买限额为26000人民币，如超过中国海关记录，货品将不获通关。</li>
                <li>跨境商品均从境外发货，符合原产地有关质量、安全、卫生、环保、标示等标准或技术规范要求，可能与我国标准有所不同，由此可能产生的危害、损失或者其他风险，将由客人自行承担。</li>
                <li>于品酒坊上购买的跨境商品等同于境外购买，商品本身无中文背标，您可通过网站查看商品中文电子标签，如果海外产品包装更换，您收到的商品可能与商品图片存在差异，请以收到的实物为准。</li>
                <li>跨境商品购物属境外消费，不能开具增值发票。</li>
              </ol>
            </span>
          </div>

          <h3 v-if="Product?.Winery && Product?.Winery.length > 0 && isWinery.length > 0">
            {{ $t.getTranslation('LBL_WINERY') }}
          </h3>
          <div
            style="width: 100%; height: 100%; background-size: contain; background-position: center; background-repeat: no-repeat"
            v-for="img in Product.Winery"
            :key="'winery_img_' + img.WineryId"
            @click="openImage(img.FileName, 'WINERY', $refs)"
          >
            <img v-if="img.FileName" :src="helpers.getImage(img.FileName, 'WINERY')" style="width: 100%; height: 100%" loading="lazy" />
          </div>
        </div>
      </div>

      <Suspense v-if="Product && userToken">
        <ProductRatingComponent v-if="Product && userToken" :product="Product" />
      </Suspense>

      <!-- <div v-if="Product.RecentRatings.length" class="recommendation-card" style="width: auto; padding: 25px; padding-top: 0px; border: none; box-shadow: none; min-height: auto">
        <h3>{{ $t.getTranslation("LBL_REVIEWS") }}</h3>
        <br />
        <div class="reviews" style="padding: 0px; border: none">
          <div v-for="(item, index) in Product.RecentRatings" :key="index" class="review" style="border: none">
            <star-rating :increment="0.1" :star-size="13" :show-rating="false" :rating="item.Rating" :read-only="true"></star-rating>
            <p v-if="item.Content">{{ item.Content }}</p>
            <div class="profile">
              <img :src="$h.getImage(item?.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />
              <h3>{{ item.FirstName }}</h3>
            </div>
          </div>
        </div>
      </div> -->
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, inject, computed, defineAsyncComponent } from 'vue'
import { get } from '@/utils/axios'
import { helpers } from '@/utils/helpers.js'
import { useStore } from '@/store'
//import { f7 } from 'framework7-vue'

// import StarRating from '@/components/rating/star-rating.vue'
// import ProductNavigationComponent from '@/components/navigations/ProductNavigationComponent.vue'
// import ProductToolbarComponent from '@/components/navigations/ProductToolbarComponent.vue'
// import ProductInventoryStatus from '@/components/status/ProductInventoryStatus.vue'
// import CountryFlag from 'vue-country-flag-next'
// import FlashSaleComponent from '@/components/FlashSaleComponent.vue'
// import ProductRatingComponent from '@/components/ProductRatingComponent.vue'
// import ProductBundleCardComponent from '@/components/cards/ProductBundleCardComponent.vue'
// import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";
// import PopupLoginComponent from "@/components/PopupLoginComponent.vue";

import '@appnest/masonry-layout'

export default defineComponent({
  name: 'ProductViewPage',
  components: {
    StarRating: defineAsyncComponent(() => import(/* webpackChunkName: "star-rating" */ /* webpackMode: "lazy" */ '@/components/rating/star-rating.vue')),
    ProductNavigationComponent: defineAsyncComponent(() => import(/* webpackChunkName: "star-rating" */ /* webpackMode: "lazy" */ '@/components/navigations/ProductNavigationComponent.vue')),
    ProductToolbarComponent: defineAsyncComponent(() => import(/* webpackChunkName: "product-tool-bar" */ /* webpackMode: "lazy" */ '@/components/navigations/ProductToolbarComponent.vue')),
    ProductInventoryStatus: defineAsyncComponent(() => import(/* webpackChunkName: "product-inventory" */ /* webpackMode: "lazy" */ '@/components/status/ProductInventoryStatus.vue')),
    CountryFlag: defineAsyncComponent(() => import(/* webpackChunkName: "country-flag" */ /* webpackMode: "lazy" */ 'vue-country-flag-next')),
    FlashSaleComponent: defineAsyncComponent(() => import(/* webpackChunkName: "flash-sale" */ /* webpackMode: "lazy" */ '@/components/FlashSaleComponent.vue')),
    ProductRatingComponent: defineAsyncComponent(() => import(/* webpackChunkName: "product-rating" */ /* webpackMode: "lazy" */ '@/components/ProductRatingComponent.vue')),
    ProductBundleCardComponent: defineAsyncComponent(() => import(/* webpackChunkName: "product-bundle" */ /* webpackMode: "lazy" */ '@/components/cards/ProductBundleCardComponent.vue'))
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const store = useStore()

    const languageCode = computed(() => store.getters['translation/getLanguage'])
    const userToken = computed(() => store.getters['user/getData']?.Token)
    const userProductCode = computed(() => store.getters['user/getData']?.ProductCode)
    const $t = inject('$translation')
    const selectedImage = ref('')
    const currentType = ref('')
    const photos = ref([])
    const descriptionPhotos = ref([])
    const KOL_CODE = ref('')
    const PostKey = ref('')

    const ProductDescriptionImages = ref([])
    const Product = ref({})
    const isGettingProd = ref(true)
    const isGrapes = ref([])
    const isWinery = ref([])
    const productCode = ref(null)
    const BundleProductId = ref(null)
    const RelatedBundleProducts = ref([])
    const navType = ref('back')

    if (props.f7route?.query?.mini) {
      navType.value = 'miniProductView'
    }

    store.dispatch('user/setData', {
      ProductCode: null,
      KolCode: null,
      PostKey: null
    })

    const translationCritic = {
      JS: {
        translation: 'LBL_CRITIC_SCORE_JS'
      },
      WS: {
        translation: 'LBL_CRITIC_SCORE_WS'
      },
      RP: {
        translation: 'LBL_CRITIC_SCORE_RP'
      }
    }

    const openImage = (img, type, $refs) => {
      let photo = helpers.getImage(img, type)
      descriptionPhotos.value = []
      descriptionPhotos.value.push(photo)
      setTimeout(() => {
        $refs.popupDark.open()
      }, 300)
    }

    const getProduct = async () => {
      let obj = {}

      if (props?.f7route?.params?.code) {
        obj['key'] = props?.f7route?.params?.code
      }

      // if (props?.f7route?.query?.sku) {
      //   obj["sku"] = props?.f7route?.query?.sku;
      // }

      obj['LanguageCode'] = languageCode.value

      if (props?.f7route?.query?.bundleId) {
        obj['BundleId'] = props?.f7route?.query?.bundleId
      }

      try {
        let ret = await get('/mobile/product/list', obj)
        if (!ret) {
          throw 'ERR_PRODUCT_NOT_FOUND'
        }

        ret.Product.Critic = _.pickBy(ret.Product.Critic, function(value, key) {
          if (!value || !(value == '')) {
            return value
          }
        })

        let DescriptionImages = ret.Product.Images.filter(function(img) {
          if (img.Type == 'i-t-2') {
            return img
          }
        })

        let PrimaryImages = ret.Product.Images.filter(function(img) {
          if (img.Type == 'i-t-1') {
            return img
          }
        })

        isGrapes.value = ret.Product.Grapes.filter(function(img) {
          if (img.FileName) {
            return img
          }
        })

        isWinery.value = ret.Product.Winery.filter(function(img) {
          if (img.FileName) {
            return img
          }
        })

        ProductDescriptionImages.value = DescriptionImages
        ret.Product.Images = PrimaryImages

        photos.value = _.map(PrimaryImages, (r) => {
          return helpers.getImage(r.FileName, 'PRODUCT')
        })

        Product.value = ret.Product
        if (Product.value.Rating <= 0) {
          Product.value.Rating = 4.5
        }
        isGettingProd.value = false
      } catch (err) {
        isGettingProd.value = false
        props.f7router.navigate({ name: 'shopPage' })
        return
      }
    }

    const goMerchant = (product) => {
      if (product?.MerchantKey) {
        props.f7router.navigate({
          name: 'merchantViewPage',
          params: { code: product?.MerchantKey }
        })
      }
    }

    const getProductRelatedForBundle = async () => {
      let ret = await get(`/mobile/product/bundle/list?ProductId=${Product.value.ProductId}`)
      for (let index = 0; index < ret.Items.length; index++) {
        let item = ret.Items[index]
        // mobile/product/public/list?page=1&size=25&LanguageCode=EN&ProductId=4948
        let findProduct = await get(
          `/mobile/product/public/list?page=1&size=1&SearchField=MULTIPLE&SearchType=LIKE&SearchValue=&FilterType=&FilterValue=&RegionId=&SubRegionId=&SupplierCode=&LanguageCode=&CountryCode=&WineryCode=&ProductId=${item.ProductId}`
        )
        if (findProduct.data.length > 0) {
          item = { ...item, ...findProduct.data[0] }
        }
        RelatedBundleProducts.value.push(item)
      }
    }

    const viewImage = ($refs, index) => {
      $refs.popup.open(index)
    }

    onMounted(async () => {
      if (userProductCode.value) {
        store.dispatch('user/setData', {
          ProductCode: null
        })
      } else {
        store.dispatch('user/setData', {
          ProductCode: props?.f7route?.query?.key
        })
      }

      // if (!userToken.value && !props?.f7route?.query?.sku) {
      //   props.f7router.navigate({ name: "loginPage" });
      //   return;
      // }

      if (props?.f7route?.query?.ref) {
        //set KOL first
        KOL_CODE.value = props.f7route.query.ref
      }
      if (props?.f7route?.query?.key) {
        //set KOL first
        PostKey.value = props.f7route.query.key
      }

      if (props?.f7route?.params?.code) {
        productCode.value = props?.f7route?.params?.code
        await getProduct()
      }

      await getProductRelatedForBundle()
    })

    const onViewProduct = (ProductKey) => {
      // if (!userToken.value) {
      //   props.f7router.navigate({ name: 'loginPage' })
      //   return
      // }
      props.f7router.navigate({
        name: 'productViewPage',
        params: { code: ProductKey },
        query: { bundleId: props.bundleId }
      })
    }

    return {
      navType,
      PostKey,
      KOL_CODE,
      photos,
      viewImage,
      isGettingProd,
      Product,
      helpers,
      goMerchant,
      translationCritic,
      selectedImage,
      ProductDescriptionImages,
      openImage,
      currentType,
      descriptionPhotos,
      isGrapes,
      isWinery,
      productCode,
      userToken,
      getProduct,
      RelatedBundleProducts,
      BundleProductId,
      onViewProduct
    }
  }
})
</script>

<style>
.alt-productview-page {
  padding-bottom: 100px;
}
.flash-content {
  position: relative;
  background-color: #fff;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 90% !important;
  height: 100%;
  border-radius: 8px;
}

.flash-badge img {
  height: 100%;
  width: 100%;
}

.flash-badge {
  position: absolute;
  height: 100px;
  width: 100px;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
}
.flash-timer {
  width: 130px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.flash-timer span {
  position: absolute;
  padding: 10px 0;
  color: #9c7835;
  font: 800 16px/1 'Lato', sans-serif;
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
}
.flash-left img {
  height: 100%;
  width: 100%;
}
.flash-badge span {
  position: absolute;
  padding: 10px 0;
  color: #9c7835;
  font: 800 16px/1 'Lato', sans-serif;
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
}

.flash-item {
  width: 220px;
  /* height: 100%; */
}
.prod-slider-container-sale {
  position: relative;
  margin-bottom: 5px;
  background-image: linear-gradient(to right, #0c4d8f, #0080ff);
}
.prod-swiper-sale {
  overflow: hidden;
}
.prod-swiper-sale .prod-image-disp-sale {
  cursor: pointer;
}
.prod-image-disp-sale {
  width: 100px;
  height: 100px;
  padding-top: 0px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  /* margin-right: 10px; */
}
.prod-swiper-sale .prod-image-disp-sale {
  width: 100%;
  padding-top: 60%;
  cursor: pointer;
}
.packing-qty-sale {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  right: 30px;
  bottom: 50px;
  z-index: 9999;
}

.flag-icon {
  padding: 0 10px;
  position: relative;
  top: 5px;
}
.packing-qty {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  right: 20px;
  bottom: 30px;
  z-index: 9999;
}

.prod-slider-container {
  position: relative;
}

.critic-score-child {
  position: relative;
}

.critic-score-child > label {
  font-weight: bold;
}

.critic-score-child > span {
  margin-left: 5px;
  padding: 5px 5px;
  background: #0c4d8f;
  color: #fff;
  border-radius: 5px;
}

.critic-score-parent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0px 10px;
}
.critic-score-parent-chs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 10px;
}

.critic-score-parent-chs > .critic-score-child > label {
  font-size: 12px;
}

@media only screen and (max-width: 475px) {
  .critic-score-parent-chs {
    flex-direction: column;
    align-items: flex-start;
  }
  .critic-score-child {
    margin: 10px;
  }
}

.critic-field {
  padding: 15px !important;
}
.critic-score {
  padding: 3px 3px;
  background: #0c4d8f;
  border-radius: 3px;
  color: #fff;
  float: right;
}
.post-rel {
  position: relative;
}

.prod-swiper video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}

.prod-swiper .swiper-pagination-bullets {
  bottom: 3px !important;
}

.prod-swiper {
  overflow: hidden;
  margin-bottom: 30px;
  padding-bottom: 35px;
}

.prod-swiper .prod-image-disp {
  width: 100%;
  padding-top: 60%;
  cursor: pointer;
}

.bundle-container {
  margin-top: 0px;
}
.bundle-container > h4 {
  text-transform: uppercase;
  margin-bottom: 15px;
}
.bundle-container .swiper-container {
  padding: 0px;
  margin-bottom: 10px;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}
.bundle-container .swiper-slide {
  width: calc(100% - 20px);
  margin: 0px;
  padding: 0px;
  margin-right: 10px;
}
.bundle-container .swiper-slide:last-child {
  margin-right: 0px;
}
.bundle-container .product-list {
  width: calc(100% - 20px);
  padding: 10px;
  min-height: 100px;
  background: #fff;
  margin-bottom: 0px;
}
.bundle-container .product-list span.year {
  display: none;
}
.bundle-container .bundle-product-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.bundle-container .bundle-product-container > .image-container {
  width: 80px;
  height: 80px;
  margin: 0px 10px 10px 0px;
  cursor: pointer;
}
.bundle-container .bundle-product-container > .image-container img {
  width: 100%;
}
.prod-details-field {
  padding: 10px;
  padding-left: 0px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}
.prod-details-field-notice {
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prod-details-field:last-child {
  border: none;
}

.prod-details-field label {
  font-weight: bold;
  margin-right: 20px;
}
.prod-details-field-notice label {
  font-weight: bold;
  padding-bottom: 20px;
}

.make-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ws-img {
  width: 60px;
  height: 40px;
  object-fit: scale-down;
}
.ribbon-best,
.ribbon-sal,
.ribbon-hot,
.ribbon-new {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon-new::before,
.ribbon-new::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #b72f2c;
}

.ribbon-new span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #ff4d4d;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}
.ribbon-hot span {
  position: absolute;
  display: block;
  width: 60px;
  padding: 15px 0;
  color: #fff;
  font: 700 13px/1 'Lato', sans-serif;
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
}
.ribbon-sal span {
  position: absolute;
  display: block;
  width: 60px;
  padding: 15px 0;
  color: #fff;
  font: 700 13px/1 'Lato', sans-serif;
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
}
.ribbon-best span {
  position: absolute;
  display: block;
  width: 60px;
  padding: 10px 0;
  color: #fff;
  font: 700 12px/1 'Lato', sans-serif;
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
}
/* top left*/
.ribbon-top-left-new {
  top: -20px;
  left: 0px;
}
.ribbon-top-left-hot {
  top: 170px;
  left: 30px;
}
.ribbon-top-left-sal {
  top: 250px;
  left: 30px;
}
.ribbon-top-left-best {
  top: 170px;
  left: 30px;
}
.ribbon-top-left-new::before,
.ribbon-top-left-new::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left-new::before {
  top: 0;
  right: 20px;
}
.ribbon-top-left-new::after {
  bottom: 50px;
  left: 0;
}
.ribbon-top-left-new span {
  right: -25px;
  top: 10px;
  transform: rotate(-45deg);
  z-index: 5;
}
.ribbon-top-left-hot span {
  right: 43px;
  top: 27px;
}
.ribbon-top-left-sal span {
  right: 43px;
  top: 27px;
}
.ribbon-top-left-best span {
  right: 43px;
  top: 27px;
}
.ribbon-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 90px;
  left: 10px;
}
.ribbon-container > div {
  min-height: 85px;
  width: 120px;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 3;
}
.bg-sticker {
  top: 30px;
  right: 30px;
}
.bg-sticker img {
  width: 95px;
  position: relative;
}
.notice-list {
  padding-left: 15px;
}
.packing-qty-view {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 3px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 5px;
  right: 20px;
  bottom: 30px;
  z-index: 9999;
  font-size: 14px;
}
</style>
